// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.navbar-laravel {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.no-underline {
    text-decoration: none !important;
}

.btn-rounded {
    border-radius: 40px;
}

.cursor-pointer {
    cursor: pointer;
}

body {
    min-height: 100vh;
}

.application-body {
    min-height: calc(100vh - 120px);
}

.page-footer {
    color: hsl(208, 14%, 76%);
    text-shadow: 0 0 1px #fff;
    font-weight: 800;
    padding: 1rem;
}

.text-warning {
    color: #cc8a09 !important;
}

.logo-color-1 {
    color: #1d2939;
    font-weight: bold;
}

.logo-color-2 {
    color: #1caf9a;
    font-weight: bold;
}

.logo-color-3 {
    color: #de7500;
}
